.element-menu-section {
    justify-content: flex-start;
}

.sub-element-name {
    font-size: 20px;
    color: #C0D21F;
    font-family: mainFont;
    width: 90%;
    text-align: center;
    margin-bottom: 12px;
    line-height: 27px;
}

.sub-element-description {
    width: 71.9%;
    font-size: 18px;
    color: #AEBBBE;
    font-family: mainFont;
    text-align: center;
    line-height: 24px;
}

.svgs-side {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: space-around;
    align-items: stretch;
    width: 100%;
    height: -webkit-fill-available;
    margin-top: 174px;
    min-width: 161px;
    min-height: 153px;
}

.svgs-side .glass {
    position: relative;
    border: 0.5px solid #F5F5F5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    min-width: 161px;
    min-height: 153px;
}

.svgs-side .bottle {
    border: 0.5px solid #F5F5F5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    min-width: 161px;
    min-height: 153px;
}

.svgs-side .glass.hide,
.svgs-side .bottle.hide {
    opacity: 35%;
    cursor: auto;
}

.number-area {
    font-size: 20px;
    color: #AEBBBE;
    font-family: mainFont;
    text-align: center;
    text-decoration: none;
    margin-bottom: 12px;
    line-height: 27px;
}

.svgs-side .bottle .svg-area svg {
    min-width: 55px;
    min-height: 55px;
    max-width: 55px;
    max-height: 55px;
}

.svgs-side .glass .svg-area svg {
    min-width: 55px;
    min-height: 55px;
    max-width: 55px;
    max-height: 55px;
}