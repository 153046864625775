.print-failed-section .main-container {
    min-height: 95vh;
}

.main-container .success-icon1 {
    margin-top: 40px;
}

.main-container .success-text1 {
    margin-top: 46.4px;
    max-width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    font-size: 26px;
    color: #FFFFFF;
    line-height: 35px;
    white-space: pre-line;
}

.main-container .success-icon2 {
    margin-top: 43.3px;
    width: 140px;
}

.main-container .success-text2 {
    margin-top: 96.7px;
    font-size: 26px;
    color: #FFFFFF;
    line-height: 35px;
}

.main-container .success-button .buy-button {
    text-decoration: none;
    width: -webkit-fill-available;
    border: 0.5px solid #F5F5F5;
    border-radius: 5px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    height: 6%;
    min-height: 50px;
}

.print-failed-section .main-container .success-button {
    width: -webkit-fill-available;
    margin-top: auto;
}