.loading-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: var(--dark-color);
    font-family: 'Oswald', sans-serif;
    font-size: 100%;
    margin: 0;
    text-align: center;
    z-index: 9999;
  }
  .wrapper {
    height: 50px;
    margin-top: calc(50vh - 25px);
    margin-left: calc(50vw - 100px);
    width: 180px;
  }
  .circle {
    border-radius: 50%;
    border: 3px var(--green-color) solid;
    float: left;
    height: 50px;
    margin: 0 5px;
    width: 50px;
  }
    .circle-1 {
      animation: move 1s ease-in-out infinite;
    }
    .circle-1a {
      animation: fade 1s ease-in-out infinite;
    }
    @keyframes fade {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
    .circle-2 {
      animation: move 1s ease-in-out infinite;
    }
    @keyframes move {
      0% {transform: translateX(0);}
      100% {transform: translateX(60px);}
    }
    .circle-1a {
      margin-left: -55px;
      opacity: 0;
    }
    .circle-3 {
      animation: circle-3 1s ease-in-out infinite;
      opacity: 1;
    }
    @keyframes circle-3 {
      0% {opacity: 1;}
      100% {opacity: 0;}
    }