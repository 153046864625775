.menu-element {
    width: 100%;
    color: var(--light-color);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.menu-element .menu-element-row .numbers-side .one>svg, .menu-element .menu-element-row .numbers-side .more>svg {
    display: block;
    width: 35px;
    height: 35px;
    margin: 0px;
}

.menu-element .menu-element-row {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.menu-element .menu-element-row {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: -webkit-fill-available;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
}

.menu-element .menu-element-header {
    color: var(--green-color);
    text-transform: uppercase;
    font-family: mainFont;
    display: flex;
}

.menu-element .menu-element-row .info-side {
    width: 60%;
    white-space: pre-line;
    text-align: left;
}

.menu-element .menu-element-row .info-side .name {
    color: #E9E9E9;
    font-family: mainFont;
    white-space: pre-line;
    margin-left: 10px;
    font-size: 18px;
}

.menu-element .menu-element-row .info-side .description {
    margin: 5px 10px 0px;
    white-space: pre-line;
}

.menu-element .menu-element-row .numbers-side {
    display: flex;
    color: #AEBBBE;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    margin-right: 12px !important;
    width: 8%;
}

.menu-element .menu-element-row .numbers-side .one>svg, .menu-element .menu-element-row .numbers-side .more>svg {
    display: none;
}

/* .menu-element .menu-element-row .numbers-side>div {
    width: calc((100% / 3) - 10px);
} */

/* .menu-element .menu-element-row .numbers-side>div:not(:last-of-type) {
    margin-right: 10px;
} */

.menu-element .menu-element-row .numbers-side .one,
.menu-element .menu-element-row .numbers-side .more {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #AEBBBE;
    width: 50%;
}

.menu-element .menu-element-header .numbers-side .one>svg,
.menu-element .menu-element-header .numbers-side .more>svg {
    width: 100%;
    height: 100%;
    display: block;
}

.menu-element .menu-element-row .order-side {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-element .menu-element-row .order-side .main-btn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    padding: 5px;
}

.menu-element .menu-element-row .order-side .main-btn:hover {
    background-color: var(--green-color);
}

.menu-element .menu-element-row .order-side .main-btn>svg {
    width: 85%;
    height: 85%;
}

.menu-element .menu-element-row .menu-lement-header {
    border: none;
}

/* .menu-element .menu-element-row .order-side .minus-btn,
.menu-element .menu-element-row .order-side .plus-btn {
    line-height: 100%;
    text-align: center;
    outline: none;
    padding: 2px;
    width: 35px;
    height: 35px;
    font-size: 29px;
    background-color: var(--overlay-color);
    color: var(--light-color);
    border: 1px solid var(--light-color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
}

.menu-element .menu-element-row .order-side .minus-btn:hover,
.menu-element .menu-element-row .order-side .plus-btn:hover {
    background-color: var(--green-color);
    color: var(--light-color);
}

.menu-element .menu-element-row .order-side .minus-btn {
    margin-right: 10px;
}

.menu-element .menu-element-row .order-side .plus-btn {
    margin-left: 10px;
}

.menu-element .menu-element-row .order-side .stock {
    display: none;
} */
.menu-element .menu-element-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
}

/** Start Media **/
@media (max-width: 285px){
    .menu-element .menu-element-row .numbers-side{
        width: 35%;
        margin-right: 12px !important;
    }
}
@media (min-width: 577px) and (max-width: 920px) {
    .menu-element .menu-element-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
    }
}

@media (min-width: 286px) and (max-width: 576px) {

    .menu-element .menu-element-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
    }
    .menu-element .menu-element-row .numbers-side{
        width: 28%;
        margin-right: 12px !important;
    }

    .menu-element .menu-element-row .numbers-side>div:not(:last-of-type) {
        margin-right: 22px !important;
    }

    .menu-element .menu-element-row .numbers-side .one>svg, .menu-element .menu-element-row .numbers-side .more>svg {
        display: block;
        width: 35px;
        height: 35px;
        margin: 0px;
    }

    .menu-element .menu-element-row .order-side {
        padding: 4px;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }

    .menu-element .menu-element-row .order-side .main-btn {
        width: 90%;
        height: 90%;
    }

}

/** End Media **/