.main-container-bottle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 120px;
    width: -webkit-fill-available;
}

.sub-element-name-glass {
    font-size: 20px;
    color: #C0D21F;
    font-family: mainFont;
    width: 90%;
    text-align: left;
    margin-bottom: 12px;
    line-height: 27px;
}

.sub-element-description-glass {
    width: 71.9%;
    font-size: 18px;
    color: #AEBBBE;
    font-family: mainFont;
    text-align: left;
    line-height: 24px;
}

.svgs-side {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: space-around;
    align-items: stretch;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    margin-top: 174px;
    min-width: 161px;
    min-height: 153px;
}

.svgs-side .glass {
    border: 0.5px solid #F5F5F5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    min-width: 161px;
    min-height: 153px;
    text-decoration: none;
}

.svgs-side .bottle {
    text-decoration: none;
    border: 0.5px solid #F5F5F5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    min-width: 161px;
    min-height: 153px;
}

.number-area {
    font-size: 20px;
    color: #AEBBBE;
    text-decoration: none;
    font-family: mainFont;
    text-align: center;
    margin-bottom: 12px;
    line-height: 27px;
}

.svgs-side .bottle .svg-area svg {
    min-width: 55px;
    min-height: 55px;
    max-width: 55px;
    max-height: 55px;
}

.svgs-side .glass .svg-area svg {
    min-width: 55px;
    min-height: 55px;
    max-width: 55px;
    max-height: 55px;
}

.buy-button {
    position: fixed;
    bottom: 0;
    background-color: var(--dark-color);
    text-decoration: none;
    width: 88.7%;
    margin: 0px auto;
    border: 0.5px solid #F5F5F5;
    border-radius: 5px;
    margin-top: 67%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    height: 6%;
    min-height: 50px;
    margin-bottom: 20px;
    margin-top: auto;
    cursor: pointer;
}

.buy-button .buy-button-text {
    font-size: 20px;
    color: #C0D21F;
    font-family: mainFont;
    text-align: center;
    text-decoration: none;
    line-height: 27px;
    width: 30%;
    flex-grow: 1;
}

.buy-button .buy-button-price {
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    font-family: mainFont;
    text-decoration: none;
    line-height: 27px;padding: 5px;
}

.buy-button .line-between {
    width: 0px;
    height: 82%;
    border: 1px solid #ADB3B6;
    min-height: 41px;
    text-decoration: none;
}

.buy-button .buy-button-minos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    align-content: center;
    width: 20%;
    height: 100%;
    cursor: pointer;
}

.buy-button .buy-button-plus {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    align-content: center;
    width: 20%;
    height: 100%;
    cursor: pointer;
}

.quantity-area {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: -webkit-fill-available;
    align-content: center;
    align-items: center;
}

.quantity-area .quantity-area-minos {
    border: 0.5px solid #F5F5F5;
    border-radius: 20px;
    font-size: 25px;
    color: #FFFFFF;
    text-align: center;
    font-family: mainFont;
    line-height: 27px;
    min-width: 53px;
    min-height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 15.3%;
    cursor: pointer;
}

.quantity-area .quantity-area-minos svg {
    height: 5px;
}

.quantity-area .quantity-area-quantity {
    font-size: 20px;
    color: #C0D21F;
    font-family: mainFont;
    text-align: center;
    line-height: 27px;
    min-width: 51px;
    display: flex;
    width: 14.7%;
    justify-content: center;
    align-items: center;
}

.quantity-area .quantity-area-plus {
    border: 0.5px solid #F5F5F5;
    border-radius: 20px;
    min-width: 53px;
    min-height: 34px;
    font-size: 25px;
    color: #FFFFFF;
    text-align: center;
    font-family: mainFont;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 15.3%;
    cursor: pointer;
}

.quantity-area .quantity-area-plus svg {
    height: 15.5px;
    width: 15.5px;
}

.quantity-area .quantity-area-svg svg {
    min-width: 39px;
    min-height: 38px;
    max-width: 39px;
    max-height: 38px;
}

.quantity-area .quantity-area-svg {
    margin-left: 16px;
}

.edit-area-for-bootle {
    margin-top: 20%;
    width: -webkit-fill-available;
}

.edit-area-for-bootle .edit-icon-plus {
    margin-left: 5.4%;
    min-width: 53px;
    min-height: 34px;
    border: 0.5px solid #F5F5F5;
    width: 13.73%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 19px;
}