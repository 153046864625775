.events-section .main-container {
  height: calc(100% - 130px);
}

.events-section .events-swiper {
  width: 100%;
  height: 100%;
}

.events-section .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: var(--light-color);

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.events-section .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.events-section .main-container .events-swiper .center-con.swiper-button-disabled {
  display: none;
}

.events-section .main-container .events-swiper .center-con {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 60px;
  height: 60px;
  z-index: 9;
  cursor: pointer;
}

.events-section .main-container .events-swiper .center-con.review-swiper-button-prev {
  right: inherit;
  left: 5px;
  transform: rotate(90deg) translateX(-50%);
  -webkit-transform: rotate(90deg) translateX(-50%);
  -moz-transform: rotate(90deg) translateX(-50%);
  -ms-transform: rotate(90deg) translateX(-50%);
  -o-transform: rotate(90deg) translateX(-50%);
}

.events-section .main-container .events-swiper .center-con.review-swiper-button-next {
  transform: rotate(-90deg) translateX(50%);
  -webkit-transform: rotate(-90deg) translateX(50%);
  -moz-transform: rotate(-90deg) translateX(50%);
  -ms-transform: rotate(-90deg) translateX(50%);
  -o-transform: rotate(-90deg) translateX(50%);
}

.events-section .main-container .events-swiper .center-con .round {
  position: relative;
  border: 2px solid var(--light-color);
  background-color: var(--overlay-color);
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.events-section .main-container .events-swiper .center-con span {
  z-index: 999;
  height: 3px;
  margin: 1px;
  width: 17px;
  background: var(--light-color);
  transition: 0.4s ease;
}

.events-section .main-container .events-swiper .center-con span:first-child {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  left: 25%;
  bottom: 35%;
}

.events-section .main-container .events-swiper .center-con span:nth-child(2) {
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  left: 45%;
  bottom: 35%;
}

.events-section .main-container .events-swiper .center-con span:nth-child(3) {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  left: 25%;
  bottom: 54%;
}

.events-section .main-container .events-swiper .center-con span:nth-child(4) {
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  left: 45%;
  bottom: 54%;
}

.events-section .main-container .events-swiper .center-con .round:hover span:nth-child(1) {
  transform: rotate(-135deg);
}

.events-section .main-container .events-swiper .center-con .round:hover span:nth-child(2) {
  transform: rotate(135deg);
}

.events-section .main-container .events-swiper .center-con .round:hover span:nth-child(3) {
  transform: rotate(225deg);
}

.events-section .main-container .events-swiper .center-con .round:hover span:nth-child(4) {
  transform: rotate(-225deg);
}

/** Start Media **/
@media (max-width: 576px) {
  .events-section .main-container {
    height: calc(100vh - 84px);
  }

  .events-section .main-container .events-swiper,
  .events-section .main-container .events-swiper .swiper-wrapper {
    height: calc(100vh - 130px);
  }

  .events-section .main-container .events-swiper .event,
  .events-section .main-container .events-swiper .swiper-slide {
    height: 100% !important;
  }

  .events-section .main-container .events-swiper .center-con.review-swiper-button-next,
  .events-section .main-container .events-swiper .center-con.review-swiper-button-prev {
    position: absolute;
    bottom: inherit;
    top: inherit;
    left: 50%;
    right: inherit;
  }

  .events-section .main-container .events-swiper .center-con.review-swiper-button-next {
    position: fixed;
    bottom: 5px;
    flex-direction: column;
    transform: rotate(0deg) translate(-50%, 0);
    -webkit-transform: rotate(0deg) translate(-50%, 0);
    -moz-transform: rotate(0deg) translate(-50%, 0);
    -ms-transform: rotate(0deg) translate(-50%, 0);
    -o-transform: rotate(0deg) translate(-50%, 0);
  }

  .events-section .main-container .events-swiper .center-con.review-swiper-button-prev {
    top: 5px;
    transform: rotate(180deg) translate(50%, 0);
    -webkit-transform: rotate(180deg) translate(50%, 0);
    -moz-transform: rotate(180deg) translate(50%, 0);
    -ms-transform: rotate(180deg) translate(50%, 0);
    -o-transform: rotate(180deg) translate(50%, 0);
  }

  .event .sub-details .text {
    font-size: 22px;
  }

  .img-infos .infos .main-title {
    font-size: 20px;
  }

  .img-infos .infos .sub-title {
    font-size: 18px;
  }

  .event .all-details .img-side .img-infos .infos {
    bottom: 20px;
  }
}

@media (min-width: 577px) and (max-width: 1024px) {
  .event .sub-details .text {
    padding: 20px 65px;
  }
}

/** End Media **/