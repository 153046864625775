.person-section {
    display: flex;
    flex-direction: column;
}

.person-section .main-container {
    min-height: calc(100vh - (130px + 29px));
}

.person-section .img-details .content-side-container {
    align-self: center;
}

.person-section .footer {
    margin-top: auto;
}