.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 6px;
    background-color: var(--footer-color);
    color: var(--light-color);
}

.footer .text {
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
}

.footer .text>a {
    text-decoration: none;
    color: var(--light-color);
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.footer .text span {
    transition: all .2s;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    -o-transition: all .2s;
}

.footer .text span:nth-child(2) {
    transition-delay: .1s;
}

.footer .text span:nth-child(3) {
    transition-delay: .2s;
}

.footer .text span:nth-child(4) {
    transition-delay: .3s;
}

.footer .text span:nth-child(5) {
    transition-delay: .4s;
}

.footer .text span:nth-child(6) {
    transition-delay: .5s;
}

.footer .text span:nth-child(7) {
    transition-delay: .6s;
}

.footer .text span:nth-child(8) {
    transition-delay: .7s;
}

.footer .text span:nth-child(9) {
    transition-delay: .8s;
}

.footer .text a>span:nth-child(1) {
    transition-delay: .9s;
}

.footer .text a>span:nth-child(2) {
    transition-delay: 1s;
}

.footer .text a>span:nth-child(3) {
    transition-delay: 1.1s;
}

.footer .text a>span:nth-child(4) {
    transition-delay: 1.2s;
}

.footer .text a>span:nth-child(5) {
    transition-delay: 1.3s;
}

.footer .text:hover span {
    font-weight: bold;
    /* text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.02), 2px 2px 0px rgba(255, 255, 255, 0.02), 3px 3px 0px rgba(255, 255, 255, 0.02); */
}

.footer .text:hover a>span {
    color: var(--green-color);
}
