.buy-section{
    padding-bottom: 20px;
}
.buy-section .content {
    width: 90%;
    height: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buy-section .content>img {
    display: block;
    width: 40%;
    height: 60vh;
    object-fit: cover;
}

.buy-section .content .infos {
    margin: 20px 0px;
    color: var(--light-color);
    width: 45%;
    display: flex;
    justify-content: space-between;
}

.buy-section .content .infos .main-infos {
    width: 75%;
}

.buy-section .content .infos .price {
    max-width: 20%;
    font-family: mainFontBold, sans-serif;
}

.buy-section .content .infos .main-infos .title {
    font-family: mainFontBold, sans-serif;
    margin-bottom: 8px;
}

.buy-section .content .infos .buy {
    margin-left: 10px;
}

.buy-section .content .order {
    color: var(--light-color);
}

.buy-section .content .order .minus-btn,
.buy-section .content .order .plus-btn {
    line-height: 100%;
    text-align: center;
    outline: none;
    padding: 2px;
    width: 35px;
    height: 35px;
    font-size: 29px;
    background-color: var(--overlay-color);
    color: var(--light-color);
    border: 1px solid var(--light-color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
}

.buy-section .content .order .minus-btn:hover,
.buy-section .content .order .plus-btn:hover {
    background-color: var(--green-color);
    color: var(--light-color);
}

.buy-section .content .order .minus-btn {
    margin-right: 10px;
}

.buy-section .content .order .plus-btn {
    margin-left: 10px;
}

.buy-section .content .order .stock {
    display: none;
}

/** Start Media **/
@media (max-width: 920px) {
    .buy-section .content>img {
        width: 100%;
        height: 60vh;
    }

    .buy-section .content .infos {
        width: 100%;
    }
}

/** End Media **/