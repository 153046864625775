.header {
    position: relative;
    padding: 30px 0px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.header .back-arrow {
    position: absolute;
    top: 50%;
    width: 13px;
    height: 23px;
    left: 20px;
    transition: all 0.25s ease-in-out;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
}

.header .back-arrow:hover {
    transform: translate(-3px, -50%);
    -webkit-transform: translate(-3px, -50%);
    -moz-transform: translate(-3px, -50%);
    -ms-transform: translate(-3px, -50%);
    -o-transform: translate(-3px, -50%);
}

.header .title {
    font-size: 32px;
    color: var(--light-color);
    font-family: mainFontBold;
}

/** Start Media **/
@media (max-width: 576px) {

    .header .back-arrow {
        width: 13px;
        height: 23px;
        left: 10px;
    }

    .header .title {
        font-size: 25px;
        max-width: 80%;
        margin: auto;
        text-align: center;
    }
}

/** End Media **/