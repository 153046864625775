.position-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--light-color);
    font-size: inherit;
    text-align: center;
    padding: 20px;
}

.position-section .main-btn {
    margin-top: 30px;
    font-size: inherit;
    cursor: pointer;
}