.img-infos {
    position: relative;
    width: 100%;
    height: 100%;
}

.img-infos>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-infos .infos {
    position: absolute;
    left: 20px;
    bottom: 20px;
    min-width: 25%;
    padding: 22px 20px;
    background-color: var(--overlay-color);
    color: var(--light-color);
}

.img-infos .infos .main-title {
    display: block;
    font-size: 28px;
}

.img-infos .infos .sub-title {
    display: block;
    font-size: 25px;
    margin-top: 12px;
}

/** Start Media **/
@media (max-width: 920px) {
    .img-infos .infos {
        bottom: 60px;
    }
}

/** End Media **/