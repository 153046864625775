.main-container .cart-orders>svg {
    width: 21px;
    height: 21px;
}

.cart-orders .order-name-cart {
    color: #E9E9E9;
    line-height: 27px;
    width: 47.1%;
    margin-right: 6.6474%;
    font-family: 'mainFontBold';
}

.main-container .order-side {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 54px;
    width: 100%;
    justify-content: space-evenly;
}

.main-container .order-side>a {
    margin-right: 2.312139%;
    margin-top: 8px;
    width: 21px;
    height: 21px;
}

.main-container .cart-orders {
    width: 100%;
    /* min-height: 665px; */
}

.main-container .cart-orders>div:last-child .line-between-orders {
    display: none;
}

.main-container .order-side>svg {
    margin-right: 2.312139%;
    margin-top: 8px;
    width: 21px;
    height: 21px;
}

.main-container .order-side .order-quantity-cart {
    margin-top: 20px;
    width: 6.36%;
    line-height: 27px;
    color: #C0D21F;
    margin-right: 21.1%;
}

.main-container .order-side .order-price-cart {
    margin-top: 20px;
    width: 6.36%;
    line-height: 27px;
    color: #AEBBBE;
    font-size: 20px;
    font-family: 'mainFontSemiBold';
}

.cart-orders .order-quantity-number-side {
    width: 110px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    border: 0.5px solid #F5F5F5;
    border-radius: 19px;
    min-height: 33px;
    min-width: 77px;
    margin-top: 23px;
    font-size: 15px;
    color: #BECF20;
    margin-left: 32px;

}

.cart-orders .order-quantity-number-side .order-quantity-number-side-text {
    margin: 0px 0px 0px 15px;
}

.cart-orders .order-quantity-number-side>svg {
    width: 23px;
    height: 22px;
    margin: 0px 8px 0px 0px;
}

.cart-orders .line-between-orders {
    border: 1px solid #AEBBBE;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

}

.cart-orders .service-services-cart {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    font-size: 16px;
    font-family: 'mainFont';
    line-height: 21px;
    color: #AEBBBE;
    min-width: 136px;
    width: 83%;
    margin-top: 5px;
}

.cart-orders .service-services-cart .service-services-cart-area {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.a-small-margin {
    margin-right: 2px;
}

@media (max-width: 576px) {
    .cart-orders .order-quantity-number-side {
        width: 95px;
    }
}