.main-container .success-icon1 {
    margin-top: 40px;
}

.main-container .success-text1 {
    margin-top: 46.4px;
    max-width: 268px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    font-size: 26px;
    color: #FFFFFF;
    line-height: 35px;
}

.main-container .success-icon2 {
    margin-top: 43.3px;
    width: 140px;
}

.main-container .success-text2 {
    margin-top: 96.7px;
    font-size: 26px;
    color: #FFFFFF;
    line-height: 35px;
}

.main-container .success-button .buy-button {
    text-decoration: none;
    width: -webkit-fill-available;
    border: 0.5px solid #F5F5F5;
    border-radius: 5px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    height: 6%;
    min-height: 50px;
}

.main-container .success-button {
    width: -webkit-fill-available;
    margin-top: 45px;

}

.main-container .success-button .buy-button {
    position: relative;
}

.main-container .discover-button {
    position: relative;
    width: fit-content;
    margin: auto;
    text-align: center;
    border: 0px;
}

.main-container .discover-button .text {
    color: var(--light-color);
}

.main-container .discover-button .text a {
    text-decoration: none;
    color: var(--light-color) !important;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.main-container .discover-button .text span {
    color: var(--light-color) !important;
    transition: all .001s;
    -webkit-transition: all .001s;
    -moz-transition: all .001s;
    -ms-transition: all .001s;
    -o-transition: all .001s;
}

.main-container .discover-button .text span:nth-child(2) {
    transition-delay: .01s;
}

.main-container .discover-button .text span:nth-child(3) {
    transition-delay: .02s;
}

.main-container .discover-button .text span:nth-child(4) {
    transition-delay: .03s;
}

.main-container .discover-button .text span:nth-child(5) {
    transition-delay: .04s;
}

.main-container .discover-button .text span:nth-child(6) {
    transition-delay: .05s;
}

.main-container .discover-button .text span:nth-child(7) {
    transition-delay: .06s;
}

.main-container .discover-button .text span:nth-child(8) {
    transition-delay: .07s;
}

.main-container .discover-button .text span:nth-child(9) {
    transition-delay: .08s;
}

.main-container .discover-button .text span:nth-child(10) {
    transition-delay: .09s;
}

.main-container .discover-button .text span:nth-child(11) {
    transition-delay: .1s;
}

.main-container .discover-button .text span:nth-child(12) {
    transition-delay: .2s;
}

.main-container .discover-button .text span:nth-child(13) {
    transition-delay: .3s;
}

.main-container .discover-button .text span:nth-child(14) {
    transition-delay: .4s;
}

.main-container .discover-button .text span:nth-child(15) {
    transition-delay: .5s;
}

.main-container .discover-button .text span:nth-child(16) {
    transition-delay: .6s;
}

.main-container .discover-button .text span:nth-child(17) {
    transition-delay: .7s;
}

.main-container .discover-button .text span:nth-child(18) {
    transition-delay: .8s;
}

.main-container .discover-button .text span:nth-child(19) {
    transition-delay: .9s;
}

.main-container .discover-button .text span:nth-child(20) {
    transition-delay: 1s;
}

.main-container .discover-button .text span:nth-child(21) {
    transition-delay: 1.1s;
}

.main-container .discover-button .text span:nth-child(22) {
    transition-delay: 1.2s;
}

.main-container .discover-button .text span:nth-child(23) {
    transition-delay: 1.3s;
}

.main-container .discover-button .text span:nth-child(24) {
    transition-delay: 1.4s;
}

.main-container .discover-button .text span:nth-child(25) {
    transition-delay: 1.5s;
}

.main-container .discover-button .text span:nth-child(26) {
    transition-delay: 1.6s;
}

.main-container .discover-button .text span:nth-child(27) {
    transition-delay: 1.7s;
}

.main-container .discover-button .text span:nth-child(28) {
    transition-delay: 1.8s;
}

.main-container .discover-button .text span:nth-child(29) {
    transition-delay: 1.9s;
}

.main-container .discover-button .text span:nth-child(30) {
    transition-delay: 2s;
}

.main-container .discover-button .text a>span:nth-child(1) {
    transition-delay: .9s;
}

.main-container .discover-button .text a>span:nth-child(2) {
    transition-delay: 1s;
}

.main-container .discover-button .text a>span:nth-child(3) {
    transition-delay: 1.1s;
}

.main-container .discover-button .text a>span:nth-child(4) {
    transition-delay: 1.2s;
}

.main-container .discover-button .text a>span:nth-child(5) {
    transition-delay: 1.3s;
}

.main-container .discover-button .text:hover span {
    font-weight: bold;

}

.main-container .discover-button .text:hover a>span {
    color: var(--green-color) !important;
}

.success-section {
    display: flex;
    flex-direction: column;
}

.success-section .footer {
    margin-top: auto;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(100px);
    }
}

svg #wheel {
    animation: scroll ease 1.5s infinite;
}

.scroll-mouse {
    cursor: pointer;
}

@media (max-width:576px) {
    .main-container .success-text1 {
        font-size: 20px !important;
    }

    .main-container .success-icon2 {
        width: 115px !important;
    }
}