.carte-section {
  display: flex;
  flex-direction: column;
}

.carte-section .footer {
  margin-top: auto;
}

.carte-section .main-container-flex {
  transition-duration: 3s;
  -webkit-animation: fadein 1s;
}

.carte-section .main-container {
  -webkit-animation: fadein 0.5s;
}

.main-container-details {
  -webkit-animation: fadein 0.5s;
}

.main-container-menu {
  -webkit-animation: fadein 0.5s;
}

.main-container-bottle {
  -webkit-animation: fadein 1s;
}

.main-container-glass {
  -webkit-animation: fadein 1s;
}

.main-container-your-order {
  -webkit-animation: fadein 0.8s;
}

.main-container-success {
  -webkit-animation: fadein 0.8s;
}

.edit-area-for-bootle .edit-icon-plus {
  -webkit-animation: fadein 0.8s;

}

.buy-button {
  -webkit-animation: fadein 0.8s;
}

.quantity-area {
  -webkit-animation: fadein 0.8s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.menu-element-row {
  width: 100%;
  height: 5.92%;
  min-height: 50px;
  display: flex;
  text-align: center;
  border: 0.5px solid white;
  border-radius: 10px;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: whitesmoke;
  text-decoration: none;
}

.menu-element-with-friend {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 66px;
  align-items: center;
}

.main-container-flex {
  width: 88.7%;
  min-height: 248px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  /* justify-content: space-between; */
  margin: 0px auto;
}

.carte-section .carte-swiper {
  width: 100%;
  height: 100%;
}

.carte-section .carte-swiper .swiper-wrapper {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
}

.carte-section .img-infos .infos .main-title {
  font-size: 22px;
}

.carte-section .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.carte-section .swiper-slide a {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carte-section .swiper-slide a:hover img {
  transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -ms-transform: scale(1.02);
  -o-transform: scale(1.02);
}

.carte-section .swiper-slide a img {
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
}

.carte-section .main-container .carte-swiper .center-con.swiper-button-disabled {
  display: none;
}

.carte-section .main-container .carte-swiper .center-con {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 60px;
  height: 60px;
  z-index: 9;
  cursor: pointer;
}

.carte-section .main-container .carte-swiper .center-con.review-swiper-button-prev {
  right: inherit;
  left: 5px;
  transform: rotate(90deg) translateX(-50%);
  -webkit-transform: rotate(90deg) translateX(-50%);
  -moz-transform: rotate(90deg) translateX(-50%);
  -ms-transform: rotate(90deg) translateX(-50%);
  -o-transform: rotate(90deg) translateX(-50%);
}

.carte-section .main-container .carte-swiper .center-con.review-swiper-button-next {
  transform: rotate(-90deg) translateX(50%);
  -webkit-transform: rotate(-90deg) translateX(50%);
  -moz-transform: rotate(-90deg) translateX(50%);
  -ms-transform: rotate(-90deg) translateX(50%);
  -o-transform: rotate(-90deg) translateX(50%);
}

.carte-section .main-container .carte-swiper .center-con .round {
  position: relative;
  border: 2px solid var(--light-color);
  background-color: var(--overlay-color);
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.carte-section .main-container .carte-swiper .center-con span {
  z-index: 999;
  height: 3px;
  margin: 1px;
  width: 17px;
  background: var(--light-color);
  transition: 0.4s ease;
}

.carte-section .main-container .carte-swiper .center-con span:first-child {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  left: 25%;
  bottom: 35%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.carte-section .main-container .carte-swiper .center-con span:nth-child(2) {
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  left: 45%;
  bottom: 35%;
}

.carte-section .main-container .carte-swiper .center-con span:nth-child(3) {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  left: 25%;
  bottom: 54%;
}

.carte-section .main-container .carte-swiper .center-con span:nth-child(4) {
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  left: 45%;
  bottom: 54%;
}

.carte-section .main-container .carte-swiper .center-con .round:hover span:nth-child(1) {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
}

.carte-section .main-container .carte-swiper .center-con .round:hover span:nth-child(2) {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
}

.carte-section .main-container .carte-swiper .center-con .round:hover span:nth-child(3) {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
}

.carte-section .main-container .carte-swiper .center-con .round:hover span:nth-child(4) {
  transform: rotate(-225deg);
  -webkit-transform: rotate(-225deg);
  -moz-transform: rotate(-225deg);
  -ms-transform: rotate(-225deg);
  -o-transform: rotate(-225deg);
}

/** Start Media **/
@media (max-width: 576px) {
  .carte-section .main-container {
    height: 75vh;
  }

  .carte-section .main-container .carte-swiper {
    height: 85vh;
  }

  .carte-section .main-container .carte-swiper .swiper-wrapper {
    height: 100%;
  }

  .carte-section .main-container .carte-swiper .swiper-slide {
    height: 80% !important;
  }

  .carte-section .main-container .carte-swiper .center-con.review-swiper-button-next,
  .carte-section .main-container .carte-swiper .center-con.review-swiper-button-prev {
    position: absolute;
    bottom: inherit;
    top: inherit;
    left: 50%;
    right: inherit;
  }

  .carte-section .main-container .carte-swiper .center-con.review-swiper-button-next {
    position: fixed;
    bottom: 5px;
    flex-direction: column;
    transform: rotate(0deg) translate(-50%, 0);
    -webkit-transform: rotate(0deg) translate(-50%, 0);
    -moz-transform: rotate(0deg) translate(-50%, 0);
    -ms-transform: rotate(0deg) translate(-50%, 0);
    -o-transform: rotate(0deg) translate(-50%, 0);
  }

  .carte-section .main-container .carte-swiper .center-con.review-swiper-button-prev {
    top: 5px;
    transform: rotate(180deg) translate(50%, 0);
    -webkit-transform: rotate(180deg) translate(50%, 0);
    -moz-transform: rotate(180deg) translate(50%, 0);
    -ms-transform: rotate(180deg) translate(50%, 0);
    -o-transform: rotate(180deg) translate(50%, 0);
  }

  .carte-section .main-container .img-infos .infos .main-title {
    font-size: 20px;
  }

  .carte-section .main-container .img-infos .infos .sub-title {
    font-size: 18px;
  }

  .carte-section .main-container .img-infos .infos {
    bottom: 18px;
  }
}

@media (min-width: 577px) and (max-width: 914px) {
  .carte-section .main-container {
    height: 85vh;
  }

  .carte-section .main-container .carte-swiper {
    height: 55%;
  }

  .carte-section .main-container .img-infos .infos {
    bottom: 22px;
  }
}

@media (min-width: 577px) and (max-width: 1024px) {}

/** End Media **/