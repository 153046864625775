.menu-item {
    width: 100%;
    color: var(--light-color);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.menu-item .menu-item-header .menu-item-name {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: -webkit-fill-available;
    min-height: 50px;
    border: 0.5px solid #808A8E;
    border-radius: 8px;
}

.menu-item .menu-item-row {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.menu-item .menu-item-row {
    margin-bottom: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: -webkit-fill-available;
    align-content: center;
    justify-content: space-between;
}

.menu-item .menu-item-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 24px;
}

.menu-item .menu-item-header .menu-item-row {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.menu-item .menu-item-row .info-side {
    white-space: pre-line;
    text-align: left;
}

.menu-item .menu-item-row .info-side .name {
    color: #E9E9E9;
    font-family: mainFont;
    white-space: pre-line;
    margin-left: 10px;
    font-size: 18px;
}

.menu-item .menu-item-row .info-side .description {
    margin: 5px 10px 0px;
    white-space: pre-line;
}

.menu-item .menu-item-row .numbers-side {
    display: flex;
    color: #AEBBBE;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    margin-right: 12px;
}

.menu-item .menu-item-row .numbers-side .one>svg, .menu-item .menu-item-row .numbers-side .more>svg {
    display: none;
}

/* .menu-item .menu-item-row .numbers-side>div {
    width: calc((100% / 3) - 10px);
} */

/* .menu-item .menu-item-row .numbers-side>div:not(:last-of-type) {
    margin-right: 10px;
} */

.menu-item .menu-item-row .numbers-side .one,
.menu-item .menu-item-row .numbers-side .more {
    text-align: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #AEBBBE;
}

.menu-item .menu-item-header .numbers-side .one>svg,
.menu-item .menu-item-header .numbers-side .more>svg {
    width: 100%;
    height: 40px;
    display: block;
}

.menu-item .menu-item-row .order-side {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-item .menu-item-row .order-side .main-btn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    padding: 5px;
}

.menu-item .menu-item-row .order-side .main-btn:hover {
    background-color: var(--green-color);
}

.menu-item .menu-item-row .order-side .main-btn>svg {
    width: 85%;
    height: 85%;
}

.menu-item .menu-item-row .menu-lement-header {
    border: none;
}

/* .menu-item .menu-item-row .order-side .minus-btn,
.menu-item .menu-item-row .order-side .plus-btn {
    line-height: 100%;
    text-align: center;
    outline: none;
    padding: 2px;
    width: 35px;
    height: 35px;
    font-size: 29px;
    background-color: var(--overlay-color);
    color: var(--light-color);
    border: 1px solid var(--light-color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
}

.menu-item .menu-item-row .order-side .minus-btn:hover,
.menu-item .menu-item-row .order-side .plus-btn:hover {
    background-color: var(--green-color);
    color: var(--light-color);
}

.menu-item .menu-item-row .order-side .minus-btn {
    margin-right: 10px;
}

.menu-item .menu-item-row .order-side .plus-btn {
    margin-left: 10px;
}

.menu-item .menu-item-row .order-side .stock {
    display: none;
} */

.menu-item .menu-item-header .menu-item-name .menu-item-text {
    margin-left: 8px;
    font-size: 24px;
    font-family: 'mainFont';
    line-height: 32px;
    color: #E9E9E9;
}

.menu-item .menu-item-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 24px;
    width: 100%;
}

.menu-item .menu-item-header .menu-item-name {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    min-height: 50px;
    border: 0.5px solid #808A8E;
    border-radius: 8px;
}

.menu-item .menu-item-header .menu-item-name .menu-item-text {
    margin-left: 8px;
    font-size: 24px;
    font-family: 'mainFont';
    line-height: 32px;
    color: #E9E9E9;
}

.menu-item .menu-item-header .menu-item-name .menu-item-price {
    margin-right: 8px;
    font-size: 20px;
    font-family: 'mainFont';
    line-height: 27px;
    color: #ADB3B6;
}

.menu-item .menu-item-header .menu-item-description {
    font-family: 'unna';
    font-size: 18px;
    line-height: 24px;
    margin-top: 16px;
    color: #AEBBBE;
}

.menu-item .menu-item-row .numbers-side>div:not(:last-of-type) {
    margin-right: 22px;
}

.menu-item .menu-item-row .numbers-side .one>svg, .menu-item .menu-item-row .numbers-side .more>svg {
    display: block;
    width: 35px;
    height: 35px;
    margin: 0px;
}

.menu-item .menu-item-row .order-side {
    padding: 4px;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.menu-item .menu-item-row .order-side .main-btn {
    width: 90%;
    height: 90%;
}



/** End Media **/