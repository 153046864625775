.main-container-glass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 135px;
    width: -webkit-fill-available;
}

.sub-element-name-glass {
    font-size: 20px;
    color: #C0D21F;
    font-family: mainFont;
    width: 90%;
    text-align: left;
    margin-bottom: 12px;
    line-height: 27px;
}

.sub-element-description-glass {
    width: 71.9%;
    font-size: 18px;
    color: #AEBBBE;
    font-family: mainFont;
    text-align: left;
    line-height: 24px;
}

.svgs-side {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-around;
    align-items: stretch;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    margin-top: 174px;
    min-width: 161px;
    min-height: 153px;
}

.svgs-side>a {
    margin-bottom: 10px;
}

.svgs-side .glass {
    border: 0.5px solid #F5F5F5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    min-width: 161px;
    min-height: 153px;
}

.svgs-side .bottle {
    border: 0.5px solid #F5F5F5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    min-width: 161px;
    min-height: 153px;
}

.number-area {
    font-size: 20px;
    color: #AEBBBE;
    font-family: mainFont;
    text-decoration: none;
    text-align: center;
    margin-bottom: 12px;
    line-height: 27px;
}

.svgs-side .bottle .svg-area svg {
    min-width: 55px;
    min-height: 55px;
    max-width: 55px;
    max-height: 55px;
}

.svgs-side .glass .svg-area svg {
    min-width: 55px;
    min-height: 55px;
    max-width: 55px;
    max-height: 55px;
}

.buy-button {
    position: fixed;
    bottom: 0;
    background-color: var(--dark-color);
    text-decoration: none;
    width: 88.7%;
    margin: 0px auto;
    border: 0.5px solid #F5F5F5;
    border-radius: 5px;
    margin-top: 67%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    height: 6%;
    min-height: 50px;
    margin-bottom: 20px;
    margin-top: auto;
    cursor: pointer;
}

.buy-button .buy-button-text {
    font-size: 20px;
    color: #C0D21F;
    font-family: mainFont;
    text-align: center;
    text-decoration: none;
    line-height: 27px;
    width: 30%;
    flex-grow: 1;
}

.buy-button .buy-button-price {
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    font-family: mainFont;
    text-decoration: none;
    line-height: 27px;
    padding: 5px;
}

.buy-button .line-between {
    width: 0px;
    height: 82%;
    border: 1px solid #ADB3B6;
    min-height: 41px;
    text-decoration: none;
}

.buy-button .buy-button-minos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    align-content: center;
    width: 20%;
    height: 100%;
    cursor: pointer;
}

.buy-button .buy-button-plus {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
    align-content: center;
    width: 20%;
    height: 100%;
    cursor: pointer;
}

.edit-area-for-bootle {
    margin-top: 20%;
    width: -webkit-fill-available;
}

.edit-area-for-bootle .edit-icon-plus {
    margin-left: 5.4%;
    min-width: 53px;
    min-height: 34px;
    border: 0.5px solid #F5F5F5;
    width: 13.73%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 19px;
}
.edit-area-for-bootle .buy-button{
    position: relative;
}
/** Start Media **/
@media (max-width: 576px) {
    .sub-element-description {
        margin-bottom: 30px;
    }

    .svgs-side {
        margin-top: auto;
    }
}

/** End Media **/