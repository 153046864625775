.menu-section {
    display: flex;
    min-height: 100vh;
    height: auto;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.menu-section .main-container {
    padding-bottom: 50px;
    width: 88.7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 60vh;
}

.menu-section .main-container .main-description {
    padding: 20px;
    margin-bottom: 35px;
    border: 2px solid var(--light-color);
    color: var(--light-color);
    width: 75%;
    white-space: pre-line;
    text-align: center;
}

.menu-element .menu-element-row .numbers-side .one {
    margin-right: 22px;
}

.menu-section .main-container .main3-description {
    width: -webkit-fill-available;
    border: 1px solid #AEBBBE;
    padding: 23px;
    color: #AEBBBE;
    font-size: 20px;
    font-family: 'mainFont';
    line-height: 27px;
    margin-top: 20px;

}

.services-items {
    margin-top: 20px;
}

/** Start Media **/
@media (max-width: 576px) {
    .menu-section .main-container .main3-description {
        width: -webkit-fill-available;
        border: 1px solid #AEBBBE;
        padding: 23px;
        color: #AEBBBE;
        font-size: 20px;
        font-family: 'mainFont';
        line-height: 27px;
        margin-top: 20px;

    }

    .services-items {
        margin-top: 20px;
    }

    .menu-section .main-container {
        width: 88.7%;
    }

    .menu-element .menu-element-row .numbers-side {
        grid-template-columns: repeat(auto-fit, 35%) 100%;
        gap: 15px 0px;
        justify-items: center;
        overflow: hidden;
        width: 35%;
    }

    .menu-element .menu-element-row .numbers-side .one {
        margin-right: 22px;
    }

    .menu-element .menu-element-row .order-side {
        margin: auto;
    }

    .menu-element .menu-element-row .numbers-side .one>svg,
    .menu-element .menu-element-row .numbers-side .more>svg {
        width: 20px;
        height: 32px;
    }



    .menu-element .menu-element-row .order-side .minus-btn {
        margin-right: 12px;
    }

    .menu-element .menu-element-row .order-side .plus-btn {
        margin-left: 12px;
    }

    .cart .number {
        font-size: 23px;
    }

    .menu-element .menu-element-row .order-side .minus-btn, .menu-element .menu-element-row .order-side .plus-btn {
        width: 40px;
        height: 40px;
    }

    .menu-section .main-container .main-description {
        width: 95%;
        padding: 15px;
    }

    .menu-section .main-container .main3-description {
        width: -webkit-fill-available;
        border: 1px solid #AEBBBE;
        padding: 23px;
        color: #AEBBBE;
        font-size: 20px;
        font-family: 'mainFont';
        line-height: 27px;
        margin-top: 20px;
    }
}

@media (min-width: 577px) and (max-width: 920px) {
    .menu-section .main-container {
        width: 88.7%;
    }

    .menu-element .menu-element-row .info-side {
        width: 60%;
    }

    .menu-element .menu-element-row .numbers-side {
        grid-template-columns: repeat(auto-fit, 35%) 100%;
        gap: 15px 0px;
        justify-items: center;
        overflow: hidden;
        width: 35%;
    }

    .menu-element .menu-element-row .numbers-side .one {
        margin-right: 22px;
    }

    .menu-element .menu-element-row .order-side {
        margin: auto;
    }

    .menu-element .menu-element-row .numbers-side .one>svg,
    .menu-element .menu-element-row .numbers-side .more>svg {
        width: 30px;
        height: 55px;
    }


    .menu-element .menu-element-row .order-side .minus-btn {
        margin-right: 12px;
    }

    .menu-element .menu-element-row .order-side .plus-btn {
        margin-left: 12px;
    }

    .cart .number {
        font-size: 23px;
    }

    .menu-element .menu-element-row .order-side .minus-btn, .menu-element .menu-element-row .order-side .plus-btn {
        width: 65px;
        height: 65px;
    }

    .menu-section .main-container .main-description {
        width: 95%;
        padding: 15px;
    }

    .menu-section .main-container .main3-description {
        width: -webkit-fill-available;
        border: 1px solid #AEBBBE;
        padding: 23px;
        color: #AEBBBE;
        font-size: 20px;
        font-family: 'mainFont';
        line-height: 27px;
        margin-top: 20px;

    }
}

/** End Media **/