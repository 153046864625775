.img-details {
    height: 75vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: var(--dark-color);
}

.img-details .img-side {
    height: 98%;
    align-self: flex-end;
    width: 50%;
    z-index: 2;
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    opacity: 25%;
}

.img-details .img-side.appear {
    animation: appearimg 0.28s linear 0.2s forwards;
    -webkit-animation: appearimg 0.28s linear 0.2s forwards;
}

.img-details .content-side-container {
    position: relative;
    width: calc(50% + 47px);
    height: fit-content;
    margin-left: -100px;
    z-index: 3;
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    opacity: 25%;
}

.img-details .content-side-container.appear {
    animation: appearcontent 0.28s linear 0.4s forwards;
    -webkit-animation: appearcontent 0.28s linear 0.4s forwards;
}

@keyframes appearimg {
    to {
        opacity: 100%;
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}

@keyframes appearcontent {
    to {
        opacity: 100%;
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}

.img-details .content-side-container .content-side {
    margin-top: 46px;
    margin-left: 46px;
    width: calc(100% - 32px);
    height: fit-content;
    max-height: calc(73vh - 47px);
    overflow-y: auto;
    background-color: var(--light-color);
    padding: 25px 30px;
    white-space: pre-line;

}

.img-details .content-side-container .border {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 75%;
    height: 100%;
    border: 47px solid var(--blue-color);
    z-index: -1;
}

/** Start Media **/
@media only screen and (max-width: 920px) {

    .img-details {
        height: auto;
        flex-direction: column;
        justify-content: center;
    }

    .img-details .img-side {
        width: 100%;
        height: 70vh;
    }

    .img-details .content-side-container {
        width: 100%;
        margin-left: 0px;
        margin-top: -47px;
    }

    .img-details .content-side-container .content-side {
        max-height: fit-content !important;
        width: calc(100% - 34px);
        margin-top: 34px;
        margin-left: 34px;
        padding: 20px 25px;
    }

    .img-details .content-side-container .border {
        border-width: 35px;
    }

}

/** End Media **/