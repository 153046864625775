.accueil-section {
    height: auto;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.accueil-section .main-content {
    background-color: var(--overlay-color);
    min-height: calc(100vh - 29px);
    width: 100%;
}

.accueil-section .main-content .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    margin: auto;
    padding: 145px 0px 0px;
}

.accueil-section .main-content .logo {
    display: block;
    width: 227px;
    height: 134px;
    margin-bottom: 25px;
}

.accueil-section .main-content .main-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.accueil-section .main-content .main-header>span {
    font-family: mainFontBold, sans-serif;
    font-size: 33px;
    color: var(--light-color);
    text-align: center;
}

.accueil-section .main-content .buttons {
    display: flex;
    margin-top: 25vh;
}

.accueil-section .main-content .buttons .main-btn {
    min-width: 180px;
    text-align: center;
}

.accueil-section .main-content .buttons .main-btn:nth-child(even) {
    border-color: var(--blue-color);
}

.accueil-section .main-content .buttons .main-btn:nth-child(even):hover {
    color: var(--blue-color);
}

.accueil-section .main-content .buttons .main-btn:not(:last-child) {
    margin-right: 70px;
}

/** Start Media **/
@media (max-width: 576px) {
    .accueil-section .main-content .main-container {
        height: 100%;
        padding: 25px 0px 30px;
    }

    .accueil-section .main-content .main-header {
        margin-top: 25px;
        margin-bottom: 250px;
    }

    .accueil-section .main-content .logo {
        width: 190px;
        height: 16vmax;
        margin-bottom: 15px;
    }

    .accueil-section .main-content .main-header>span {
        font-size: 6vmin;
    }

    .accueil-section .main-content .buttons {
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin-top: 0px;
    }

    .accueil-section .main-content .buttons .main-btn {
        width: 100%;
    }

    .accueil-section .main-content .buttons .main-btn:not(:last-child) {
        margin-bottom: 40px;
        margin-right: 0px;
    }
}

@media (min-width: 577px) and (max-width: 820px) {
    .accueil-section .main-content .main-container {
        height: 100%;
        padding: 25px 0px 30px;
    }

    .accueil-section .main-content .main-header {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .accueil-section .main-content .logo {
        width: 190px;
        height: 16vmax;
        margin-bottom: 15px;
    }

    .accueil-section .main-content .main-header>span {
        font-size: 6vmin;
    }

    .accueil-section .main-content .buttons {
        flex-direction: column;
        align-items: center;
        width: fit-content;
    }

    .accueil-section .main-content .buttons .main-btn {
        width: 100%;
        padding: 20px 22px;
    }

    .accueil-section .main-content .buttons .main-btn:not(:last-child) {
        margin-bottom: 40px;
        margin-right: 0px;
    }
}

@media (min-width: 821px) and (max-width: 1024px) {
    .accueil-section .main-content .main-container {
        height: 100%;
        padding: 25px 0px 15px;
    }

    .accueil-section .main-content .logo {
        height: 25vmin;
        margin-bottom: 15px;
    }

    .accueil-section .main-content .main-header>span {
        font-size: 6vmin;
    }

    .accueil-section .main-content .buttons {
        flex-wrap: wrap;
        justify-content: center;
    }

    .accueil-section .main-content .buttons .main-btn {
        padding: 15px 17px;
    }

    .accueil-section .main-content .buttons .main-btn:not(:last-child) {
        margin-right: 25px;
    }
}

/** End Media **/