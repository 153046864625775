.event {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
}

.event .all-details {
    height: 100%;
    width: 100%;
    z-index: 1;
}

.event .all-details .img-side,
.event .all-details .text-side {
    width: 100%;
    height: 50%;
}

.event .all-details .img-side .img-infos .infos {
    padding: 15px;
    text-align: left;
    font-size: 22px;
}

.event .all-details .text-side {
    padding: 20px 25px;
    font-size: 18px;
    color: var(--dark-color);
    text-align: left;
    overflow-y: auto;
    white-space: pre-line;
}

.event .sub-details {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.25s ease-in-out;
    z-index: 2;
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
}

.event .sub-details>img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
}

.event:hover .sub-details>img {
    transform: scale(1.08);
    -webkit-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
    -o-transform: scale(1.08);
}

.event .sub-details .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light-color);
    font-size: 27px;
    line-height: 35px;
    text-align: center;
}

.event .sub-details .text>p {
    max-height: 95%;
    padding: 20px 25px;
    overflow-y: auto;
    white-space: pre-line;
}

.event .blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background-color: #edf8f72e;
    z-index: 3;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transition: all 0.8s ease-in-out;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    -ms-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out;
}

.event.not-choosen .blur {
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
}

.event.choosen .sub-details {
    transform: translateY(180%);
    -webkit-transform: translateY(180%);
    -moz-transform: translateY(180%);
    -ms-transform: translateY(180%);
    -o-transform: translateY(180%);
}