.cart-container {
    position: fixed;
    bottom: 17px;
    right: 22px;
    width: 95px;
    height: 55px;
}

.cart-container .cart {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.cart-container .cart .cart-second-layer {
    width: 100%;
    height: 100%;
    background-color: #37484F;
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: 5px 5px 7px #2d2c2c31;
    filter: drop-shadow(0px 3px 6px #00000010);
    padding-left: 15px;
}


.cart .number {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 30px;
    height: 30px;
    padding: 10px;
    border: 0.5px solid var(--green-color);
    border-radius: 50%;
    text-align: center;
    color: var(--green-color);
    background-color: var(--dark-color);
    font-family: sans-serif;
    line-height: 27px;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    animation-name: move;
    animation-duration: 1s;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.cart>svg {
    position: absolute;
    width: 17.97px;
    height: 25px;
    display: block;
    opacity: 83.5%;
    z-index: 10;
    left: 18.7px;
    bottom: 16px;
    top: 7px;
}

@keyframes move {
    0% {
        bottom: 16px;
    }

    18% {
        bottom: 8px;
    }

    36% {
        bottom: 4px;
    }

    50% {
        bottom: 0px;
    }

    66% {
        bottom: 4px;
    }

    82% {
        bottom: 8px;
    }

    100% {
        bottom: 16px;
    }
}