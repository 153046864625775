.select-area>h2 {
    font-size: inherit;
}

.edit-area-for-bootle {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #39474ee6;
    padding: 10px 0px;
    margin-top: auto;
    width: 100%;
}

.edit-area-for-bootle .edit-icon-plus {
    margin-left: 5.4%;
    margin-bottom: 20px;
    min-width: 53px;
    min-height: 34px;
    border: 0.5px solid #F5F5F5;
    width: 13.73%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 19px;
    background-color: var(--dark-color);
}

.edit-menu-section .main-container {
    padding-bottom: 110px;
}

.edit-menu-section .select-area {
    display: flex;
    width: fit-content;
    align-self: baseline;
}

.edit-area-for-bootle .buy-button-without {
    position: relative;
}