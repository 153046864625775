.main-container .select-area {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    justify-content: center;
    gap: 25px;
    font-size: 20px;
    line-height: 27px;
    margin-top: 3.5rem;
    color: #E9E9E9;
    margin-bottom: 3rem;
    width: 80%;
}

.main-container .select-area .select-option {
    border: 0.5px solid #F5F5F5;
    border-radius: 8px;
    padding: 8px;
    text-align: center;
    cursor: pointer;
}

.main-container .select-area .option-selected {
    border: 0.5px solid #C0D21F;
    border-radius: 8px;
    text-align: center;
    color: #C0D21F;
    padding: 8px;
}

.main-container .select-area .select-option:hover {
    border: 0.5px solid #C0D21F;
    border-radius: 8px;
    text-align: center;
}

.main-container .selectable-services {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
}

.main-container .selectable-services .selectable-service-name {
    margin: 0px 0px 21px;
    font-size: 20px;
    font-family: 'mainFont';
    line-height: 27px;
    color: #C0D21F;
}

.main-container .selectable-services .selectable-service-elems {
    width: 100%;
    margin-bottom: 13px;
}

.main-container .selectable-services .selectable-service-elem-name {
    font-size: 16px;
    font-family: 'mainFont';
    line-height: 21px;
    width: 70%;
    color: #E9E9E9;
    border: 0px solid #F5F5F5;
    border-radius: 8px;
    padding: 8px;
}

.main-container .selectable-services .selectable-service-elem-description,
.service-item .description {
    width: 90%;
    font-size: 13px;
    margin-top: 4px;
    color: #8D9B9F;
    line-height: 17px;
    font-family: 'mainFont';
    margin-left: 8px;
}

.service-item .description {
    width: fit-content;
    align-self: baseline;
    margin-bottom: 18px;
}

.buy-button-without {
    position: fixed;
    bottom: 0;
    background-color: var(--dark-color);
    text-decoration: none;
    width: 88.7%;
    margin: 0px auto;
    border: 0.5px solid #F5F5F5;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 6%;
    min-height: 50px;
    margin-bottom: 10px;
    cursor: pointer;
    margin-top: auto;
}

.buy-button-without .buy-button-without-text {
    font-size: 24px;
    color: #C0D21F;
    font-family: mainFont;
    text-decoration: none;
    text-align: center;
    line-height: 32px;
}

.buy-button-without .buy-button-without-price {
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    font-family: mainFont;
    line-height: 27px;
    text-decoration: none;
}

.buy-button-without .space {
    min-width: 16px;
    text-decoration: none;
}


.main-container .non-selectable-services {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
}

.main-container .non-selectable-services .non-selectable-service-name {
    margin: 0px 0px 21px;
    font-size: 20px;
    font-family: 'mainFont';
    line-height: 27px;
    color: #C0D21F;
}

.main-container .non-selectable-services .non-selectable-service-elems {
    width: 100%;
    margin-bottom: 1rem;
}

.main-container .non-selectable-services .non-selectable-service-elem-name {
    font-size: 16px;
    font-family: 'mainFont';
    line-height: 21px;
    color: #E9E9E9;
    border: 0.5px solid #F5F5F5;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
}

.main-container .non-selectable-services .non-selectable-service-elem-description {
    margin-left: 8px;
    width: 90%;
    font-size: 13px;
    margin-top: 4px;
    color: #8D9B9F;
    line-height: 17px;
    font-family: 'mainFont';
}

.main-container .selectable-services .selectable-service-elems .selectable-service-elem-name1 {
    font-size: 16px;
    font-family: 'mainFont';
    line-height: 21px;
    width: 70%;
    color: #E9E9E9;
    border: 0.5px solid #F5F5F5;
    border-radius: 8px;
    padding: 8px;
}

.main-container .non-selectable-services .non-selectable-service-elem-EnnerName {
    max-width: 65%;
}

.main-container .non-selectable-services .description,
.main-container .selectable-services .description {
    font-size: 13px;
    margin-top: 4px;
    color: #8D9B9F;
    line-height: 17px;
    font-family: 'mainFont';
    margin-left: 8px;
    width: fit-content;
    align-self: baseline;
    margin-bottom: 18px;
}

.non-selectable-services .blocked>div {
    opacity: 0.32;
}

.non-selectable-services .selected>div {
    color: #C0D21F;
}

/** Start Media **/
@media (max-width: 576px) {
    .main-container .select-area {
        width: 100%;
        gap: 15px;
    }

    .main-container .selectable-services .selectable-service-name {
        font-size: 18px;
        margin-bottom: 12px;
    }
}

/** End Media **/