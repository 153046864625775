.service-item {
    width: 100%;
    color: var(--light-color);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.services-items {
    width: 100%;
}

.service-item .service-item-row {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.service-item .service-item-row {
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    align-content: center;
    justify-content: space-between;
    text-decoration: none;
}

.service-item .service-item-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 24px;
}

.service-item .service-item-header .service-item-row {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.service-item .service-item-row .info-side {
    white-space: pre-line;
    text-align: left;
}

.service-item .service-item-row .info-side .name {
    color: #E9E9E9;
    font-family: mainFont;
    white-space: pre-line;
    margin-left: 8px;
    font-size: 16px;
    line-height: 21px;
}

.service-item .service-item-row .info-side .description {
    margin: 5px 8px 0px;
    white-space: pre-line;
    font-size: 13px;
    font-family: 'mainFont';
    color: #8D9B9F;
    line-height: 17px;
}

.service-item .service-item-row .numbers-side {
    display: flex;
    color: #AEBBBE;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    margin-right: 12px;
}

.service-item .service-item-row .numbers-side .one>svg, .service-item .service-item-row .numbers-side .more>svg {
    display: none;
}

/* .service-item .service-item-row .numbers-side>div {
    width: calc((100% / 3) - 10px);
} */

/* .service-item .service-item-row .numbers-side>div:not(:last-of-type) {
    margin-right: 10px;
} */

.service-item .service-item-row .numbers-side .one,
.service-item .service-item-row .numbers-side .more {
    text-align: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #AEBBBE;
}

.service-item .service-item-header .numbers-side .one>svg,
.service-item .service-item-header .numbers-side .more>svg {
    width: 100%;
    height: 40px;
    display: block;
}

.service-item .service-item-row .order-side {
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-item .service-item-row .order-side .main-btn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    padding: 5px;
}

.service-item .service-item-row .order-side .main-btn:hover {
    background-color: var(--green-color);
}

.service-item .service-item-row .order-side .main-btn>svg {
    width: 85%;
    height: 85%;
}

.service-item .service-item-row .service-lement-header {
    border: none;
}

/* .service-item .service-item-row .order-side .minus-btn,
.service-item .service-item-row .order-side .plus-btn {
    line-height: 100%;
    text-align: center;
    outline: none;
    padding: 2px;
    width: 35px;
    height: 35px;
    font-size: 29px;
    background-color: var(--overlay-color);
    color: var(--light-color);
    border: 1px solid var(--light-color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
}

.service-item .service-item-row .order-side .minus-btn:hover,
.service-item .service-item-row .order-side .plus-btn:hover {
    background-color: var(--green-color);
    color: var(--light-color);
}

.service-item .service-item-row .order-side .minus-btn {
    margin-right: 10px;
}

.service-item .service-item-row .order-side .plus-btn {
    margin-left: 10px;
}

.service-item .service-item-row .order-side .stock {
    display: none;
} */
.service-item .service-item-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
    width: 100%;
}

.service-item .service-item-header .service-item-name {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    min-height: 50px;
}

.service-item .service-item-header .service-item-name .service-item-text {
    font-size: 24px;
    font-family: 'mainFont';
    line-height: 32px;
    color: #C0D21F;
}

.service-item .service-item-header .service-item-name .service-item-price {
    margin-right: 8px;
    font-size: 20px;
    font-family: 'mainFont';
    line-height: 27px;
    color: #ADB3B6;
}

.service-item .service-item-header .service-item-description {
    font-family: 'unna';
    font-size: 18px;
    line-height: 24px;
    margin-top: 16px;
    color: #AEBBBE;
}

.service-item .service-item-row .numbers-side>div:not(:last-of-type) {
    margin-right: 22px;
}

.service-item .service-item-row .numbers-side .one>svg, .service-item .service-item-row .numbers-side .more>svg {
    display: block;
    width: 35px;
    height: 35px;
    margin: 0px;
}

.service-item .service-item-row .order-side {
    padding: 4px;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.service-item .service-item-row .order-side .main-btn {
    width: 90%;
    height: 90%;
}

/** Start Media **/
@media (max-width: 576px) {

    .service-item .service-item-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        font-size: 24px;
    }

    .service-item .service-item-header .service-item-name {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        width: -webkit-fill-available;
        min-height: 50px;
    }

    .service-item .service-item-header .service-item-name .service-item-text {
        font-size: 24px;
        font-family: 'mainFont';
        line-height: 32px;
        color: #C0D21F;
    }

    .service-item .service-item-header .service-item-name .service-item-price {
        margin-right: 8px;
        font-size: 20px;
        font-family: 'mainFont';
        line-height: 27px;
        color: #ADB3B6;
    }

    .service-item .service-item-header .service-item-description {
        font-family: 'unna';
        font-size: 18px;
        line-height: 24px;
        margin-top: 16px;
        color: #AEBBBE;
    }

    .service-item .service-item-row .numbers-side>div:not(:last-of-type) {
        margin-right: 22px;
    }

    .service-item .service-item-row .numbers-side .one>svg, .service-item .service-item-row .numbers-side .more>svg {
        display: block;
        width: 35px;
        height: 35px;
        margin: 0px;
    }

    .service-item .service-item-row .order-side {
        padding: 4px;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }

    .service-item .service-item-row .order-side .main-btn {
        width: 90%;
        height: 90%;
    }

}

/** End Media **/