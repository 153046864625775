.producteur {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.producteur:hover .person-img {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
}

.producteur .person-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}

.producteur .name {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--overlay-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: mainFontBold, sans-serif;
    font-size: 30px;
    color: var(--light-color);
}

@media (max-width: 576px) {
    .producteur .name {
        font-size: 20px;
        padding: 5px;
    }
}