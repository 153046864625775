.producteurs-section {
  display: flex;
  flex-direction: column;
}

.producteurs-section .footer {
  margin-top: auto;
}

.producteurs-section .main-container {
  height: calc(100% - (138px));
  width: calc(100% - 170px);
  padding-bottom: 0;
}

.producteurs-section .main-container .producteurs {
  height: 100%;
}

.producteurs-section .main-container .producteurs .swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.producteurs-section .main-container .producteurs .swiper .swiper-wrapper {
  margin: auto;
}

.producteurs-section .main-container .producteurs .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: var(--light-color);
  height: calc((100% - 30px) / 2);
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}


.producteurs-section .main-container .producteurs .swiper .center-con.swiper-button-disabled {
  display: none;
}

.producteurs-section .main-container .producteurs .swiper .center-con {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 60px;
  height: 60px;
  z-index: 9;
  cursor: pointer;
}

.producteurs-section .main-container .producteurs .swiper .center-con.review-swiper-button-prev {
  right: inherit;
  left: 5px;
  transform: rotate(90deg) translateX(-50%);
  -webkit-transform: rotate(90deg) translateX(-50%);
  -moz-transform: rotate(90deg) translateX(-50%);
  -ms-transform: rotate(90deg) translateX(-50%);
  -o-transform: rotate(90deg) translateX(-50%);
}

.producteurs-section .main-container .producteurs .swiper .center-con.review-swiper-button-next {
  transform: rotate(-90deg) translateX(50%);
  -webkit-transform: rotate(-90deg) translateX(50%);
  -moz-transform: rotate(-90deg) translateX(50%);
  -ms-transform: rotate(-90deg) translateX(50%);
  -o-transform: rotate(-90deg) translateX(50%);
}

.producteurs-section .main-container .producteurs .swiper .center-con .round {
  position: relative;
  border: 2px solid var(--light-color);
  background-color: var(--overlay-color);
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.producteurs-section .main-container .producteurs .swiper .center-con span {
  z-index: 999;
  height: 3px;
  margin: 1px;
  width: 17px;
  background: var(--light-color);
  transition: 0.4s ease;
}

.producteurs-section .main-container .producteurs .swiper .center-con span:first-child {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  left: 25%;
  bottom: 35%;
}

.producteurs-section .main-container .producteurs .swiper .center-con span:nth-child(2) {
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  left: 45%;
  bottom: 35%;
}

.producteurs-section .main-container .producteurs .swiper .center-con span:nth-child(3) {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  left: 25%;
  bottom: 54%;
}

.producteurs-section .main-container .producteurs .swiper .center-con span:nth-child(4) {
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  left: 45%;
  bottom: 54%;
}

.producteurs-section .main-container .producteurs .swiper .center-con .round:hover span:nth-child(1) {
  transform: rotate(-135deg);
}

.producteurs-section .main-container .producteurs .swiper .center-con .round:hover span:nth-child(2) {
  transform: rotate(135deg);
}

.producteurs-section .main-container .producteurs .swiper .center-con .round:hover span:nth-child(3) {
  transform: rotate(225deg);
}

.producteurs-section .main-container .producteurs .swiper .center-con .round:hover span:nth-child(4) {
  transform: rotate(-225deg);
}

/** Start Media **/
@media (max-width: 576px) {
  .producteurs-section {
    height: 100vh;
  }

  .producteurs-section .main-container {
    min-height: auto;
    height: calc(80vh - 29px);
    width: calc(100% - 20px);
  }

  .producteurs-section .main-container .producteurs .swiper {
    position: relative;
  }

  .producteurs-section .main-container .producteurs .swiper .swiper-slide {
    height: 25vh;
    width: 50%;
  }

  .producteurs-section .main-container .producteurs .swiper .center-con.review-swiper-button-next,
  .producteurs-section .main-container .producteurs .swiper .center-con.review-swiper-button-prev {
    position: absolute;
    bottom: inherit;
    top: inherit;
    left: 50%;
    right: inherit;
  }

  .producteurs-section .main-container .producteurs .swiper .center-con.review-swiper-button-next {
    position: fixed;
    bottom: calc(5px + 29px);
    flex-direction: column;
    transform: rotate(0deg) translate(-50%, 0);
    -webkit-transform: rotate(0deg) translate(-50%, 0);
    -moz-transform: rotate(0deg) translate(-50%, 0);
    -ms-transform: rotate(0deg) translate(-50%, 0);
    -o-transform: rotate(0deg) translate(-50%, 0);
  }

  .producteurs-section .main-container .producteurs .swiper .center-con.review-swiper-button-prev {
    top: 5px;
    transform: rotate(180deg) translate(50%, 0);
    -webkit-transform: rotate(180deg) translate(50%, 0);
    -moz-transform: rotate(180deg) translate(50%, 0);
    -ms-transform: rotate(180deg) translate(50%, 0);
    -o-transform: rotate(180deg) translate(50%, 0);
  }

}

@media (min-width: 577px) and (max-width: 920px) {
  .producteurs-section .main-container {
    width: calc(100% - 40px);
  }

  .producteurs-section .main-container .producteurs {
    height: 80vh;
  }
}

/** End Media **/